// Entry point for the Rails JavaScript application

import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo;

import { Application } from "@hotwired/stimulus";
const application = Application.start();
window.Stimulus = application;

import * as bootstrap from "bootstrap";

// Initialize all popovers
document.querySelectorAll('[data-bs-toggle="popover"]').forEach((element) => {
  new bootstrap.Popover(element);
});

import { createPopper } from "@popperjs/core";
